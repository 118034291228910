:root[color-mode="light"] {
  --brand-hue: 202;
  --brand-saturation: 85%;
  --brand-lightness: 26%;

  --text1: #000000;
  --text2: #193bbd;
  --text3: #366163;
  --text3-transparent: #366163f0;

  --text4-hue: 228;
  --text4-saturation: 77%;
  --text4-lightness: 22%;
  --text4: hsl(var(--text4-hue), var(--text4-saturation), var(--text4-lightness));
  --text4-1: hsl(var(--text4-hue), calc(var(--text4-saturation) + 20%), calc(var(--text4-lightness) + 20%));
  --text4-2: hsl(var(--text4-hue), calc(var(--text4-saturation) - 20%), calc(var(--text4-lightness) - 20%));

  --text5: #0d1e63;
  --text6: #0d1e63;

  --text-disabled: #bbbbbb;
  --text-em: var(--text2);
  --text-notice: var(--text6);
  --text-error: #ff0000;

  // --surface1: hsl(var(--brand-hue) var(--brand-saturation) var(--brand-lightness));
  --surface1: #edf2f9;
  --surface1-transparent: #edf2f900;
  --surface2: #ffffff;
  --surface2-transparent: #ffffff00;
  --surface3: #dddddd;
  --surface4: hsl(var(--brand-hue) 5%  20%);
  --surface5: #366163;
  --surface5-transparent: #36616300;
  --surface6: #d5e5fa;
  --surface7: #6f7db4;

  // Node colors
  // Increase the HSL lightness by 5% for the tinted properties
  --node-color-1: hsl(210 41% 78%);
  --node-color-1-tinted: hsl(210 41% 83%);
  --node-color-2: hsl(33 60% 91%);
  --node-color-2-tinted: hsl(33 60% 96%);
  --node-color-3: hsl(225, 29%, 41%);
  --node-color-3-tinted: hsl(225, 29%, 46%);
  --node-color-4: hsl(206 66% 32%);
  --node-color-4-tinted: hsl(206 66% 37%);

  // Chatbot
  --chatbot-container-bg: #61a1fe;

  // Chart colors
  --chart-title-color: var(--text2);
  --chart-bg: var(--surface5);
  --chart-fill-color: var(--surface1);
  --chart-tick-color: var(--text4);
  --chart-gradient-start: #0000ff; // Avoid leading space before RGB value
  --chart-gradient-end: #00065e; // Avoid leading space before RGB value

  // Background sphere
  --sphere-color-1: #fff;
  --sphere-color-2: #333;
  --sphere-color-3: #888;

  // Misc properties
  --blob-file-upload-gradient-start: var(--surface6);
  --blob-file-upload-gradient-end: var(--surface1);
  --btn-close-filter: grayscale(100%) brightness(200%);
  --btn-primary-hover-color: var(--surface3);
  --drill-progress-circle-full: var(--surface3);
  --drill-progress-circle-partial: var(--text5);
  --drag-color: var(--text2);
  --dropdown-item-extra-color: var(--surface2);
  --fitness-overdue-bg: var(--surface7);
  --fitness-overdue-color: var(--surface1);
  --form-bg: var(--surface3);
  --form-bg-disabled: var(--surface2);
  --form-bg-focus: var(--surface1);
  --form-border-color-hover: var(--text3);
  --form-color: var(--text4);
  --hover-bg: var(--surface3);
  --link-color: var(--text2);
  --notice-color: var(--surface1);
  --notice-title-color: var(--surface1);
  --search-splitter-bg: var(--surface7);
  --search-splitter-color: var(--surface6);
  --selected-bg: var(--surface6);
  --selected-color: var(--text1);
  --selected-secondary-color: var(--surface1);
  --sidebar-active-bg: var(--surface5);
  --sidebar-active-color: var(--surface5);
  --sidebar-bg: var(--surface6);
  --sidebar-color: var(--text5);
  --sidebar-gradient-start: var(--surface6);
  --sidebar-gradient-end: var(--surface1);
  --sidebar-hover-bg: var(--surface1);
  --sidebar-hover-color: var(--surface4);
  --sidebar-icon-color1: var(--text4);
  --sidebar-icon-color2: var(--text3);
  --sidebar-icon-color3: var(--text6);
  --title-gradient-end: hsl(204 100% 59%);
  --title-gradient-start: hsl(0 0% 0%);
  --toggle-button-color-toggled: var(--text4);
  --tooltip-bg: var(--surface3);
  --tooltip-color: var(--color2);

  // Override Bootstrap CSS properties
  --bs-body-color: var(--text1);
  --bs-body-bg: var(--surface1);
  --bs-list-group-border-color: var(--surface3);
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: .75rem;
  --bs-list-group-item-secondary: var(--text2);
  --bs-modal-header-border-color: var(--text3);
  --bs-secondary: var(--text2);
  --card-spacer-y: 1.25;
  --card-spacer-x: 1.25;

  // Oruga
  --oruga-table-color: var(--text1);
  --oruga-table-background-color: transparent;
  --oruga-table-hoverable-background-color: var(--hover-bg);
  --oruga-table-td-border: var(--text1);

  .btn-primary {
    --btn-primary-hover-color: var(--surface3);
  }
  .list-group {
    --bs-list-group-border-color: var(--surface5);
  }

  // Media Chrome
  --media-control-background: var(--surface1);
  --media-background-color: var(--surface1);
  --media-icon-color: var(--text1);
}
