* {
  --bs-dropdown-zindex: 2000 !important;
  --bs-popover-body-padding-x: 0.5rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-bg: var(--text2);
  --bs-popover-max-width: 75rem !important;
  --border-radius1: 0.375rem; // bootstrap default, defined by $border-radius
  --border-radius2: 0.40rem;
  --collection-list-item-width: 13rem;
  --dropdown-height: 2.5rem;
  --grid-margin: 1rem;
  --hover-bg-transparent: var(--surface1-transparent);
  --search-box-width: 30rem;
}

:root[eye-candy="True"] {
  --eye-candy: blur(1.56rem) brightness(170%);
}

:root[eye-candy="False"] {
  --eye-candy: none;
}

// Background glowing sphere
div.sphere {
  position: absolute;
  z-index: -1;
  top: -9.37rem;
  left: calc(50% - 150px);
  width: 28.75rem;
  height: 18.75rem;
  border-radius: 50%;
  box-shadow: inset 0 0 3.12rem var(--sphere-color-1), inset 1.25rem 0 5rem var(--sphere-color-2), inset -1.25rem 0 5rem var(--sphere-color-3), inset 1.25rem 0 18.75rem var(--sphere-color-2), inset -1.25rem 0 18.75rem var(--sphere-color-3), 0 0 3.12rem var(--sphere-color-1), 0.62rem 0 25rem var(--sphere-color-2);
}

// Use this to avoid layout shift caused by scrollbars
.scroll-container {
  overflow: scroll;

  @supports (scrollbar-gutter: stable) {
    overflow: auto;
    scrollbar-gutter: stable;
  }
}

// Used on the last block in a flex column to extend to the bottom of the page
.extend-block {
  flex: 1 0 auto;
}

// Use this to create Flexbox containers of equal width
.flex-even {
  flex: 1;
}

.flex-grow-last > div:last-child {
  flex-grow: 1;
}

.card-title {
  color: var(--text4);
  font-size: 1.2rem;

  a {
    color: var(--text4);
  }
}

.card-title-large {
  @extend .card-title;
  font-size: 1.5rem;
}

.card-grid {
  padding: 0 calc(var(--grid-margin) * 0.5);
  margin: 0 0 var(--grid-margin);
}

.editable-textarea {
  textarea {
    background-color: var(--surface5);
    color: var(--text1);
  }
  label p:last-child {
    margin-bottom: 0;
  }
}

.sticky-top {
  position: sticky !important;
  // Bootstrap defines z-index: 1020 for this class,
  //  but we need it to be less than the v-sidebar-menu
  //  z-index of 100. Otherwise it gets obscured.
  z-index: 99 !important;
  top: 1rem;
}

[hidden] {
  display: none !important;
}

.hidden {
  display: none;
}

.message-error {
  background: linear-gradient(90deg, rgba(121,9,9,1) 0%, rgba(38,34,70,1) 54%);
  background-color: #262246;

  strong {
    color: var(--text3);
  }
}

.message-info {
  background: rgb(16,49,58);
  background: linear-gradient(90deg, rgba(16,49,58,1) 0%, rgba(38,34,70,1) 100%);

  strong {
    color: var(--text3);
  }
}

div.drag-target {
  transition: all 1s;

  img {
    border: 0.06rem solid rgba(0, 0, 0, 0);
  }
}

.drag-over {
  filter: drop-shadow(0.12rem 0.12rem 0.3rem var(--text3)) drop-shadow(-0.12rem -0.12rem 0.3rem var(--text3));
  transition: all 1s;
}

.zoomable {
  @extend .zoom;
}

// Override default Sortable classes
.sortable-drag {
  background-color: var(--surface5);
}

.node-draggable {
  background-color: var(--node-color-1) !important;
}

// Drag and drop not controlled by Sortable uses
//  this class (eg Buefy tables)
.dragging {
  background-color: var(--surface5) !important;
}


/* Used to hide mustached content before Vue has loaded */
[v-cloak] {
  display: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  border-radius: 0.6rem;
}

::-webkit-scrollbar-thumb {
  background-color: var(--surface3);
  border-radius: 0.6rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--surface5);
}

::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0.5);
}

.close-button {
  color: var(--text3);
}

.object-select-suggestion {
  height: 4.7rem;
  padding: 0.5rem;
  margin-bottom: 1rem;

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    color: var(--text2);
    font-size: small;
  }
}

.object-select-misc-item-below {
  background-color: var(--surface4);
  color: var(--text1);

  strong {
    color: $primary;
  }
}

//
// Fonts
//

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Lato-Regular.ttf") format("truetype");
}

//
// Common selectors
//

body {
  min-height: 100vh;
  background: rgb(0, 6, 34) url("../img/background-tile-dark.jpg") repeat-x 0 0;
  background-attachment: fixed;
  background-position: top;
  font-family: "Lato", sans-serif;
  line-height: 2rem;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
}

a {
  color: var(--link-color);
  text-decoration-color: transparent;
  text-underline-offset: 0.25rem;

  &:hover {
    color: var(--text4);
  }
}

hr {
  background-color: $primary;
}

// Override Bootstrap's CSS

.badge {
  background-color: var(--surface5);
}

.btn-close {
  // Mimics the Bootstrap "btn-close-white" class
  -webkit-filter: var(--btn-close-filter);
  filter: var(--btn-close-filter);
}

.btn-primary {
  background-color: var(--surface1);
  color: var(--text1);

  &:hover {
    background-color: var(--surface5);
    color: var(--btn-primary-hover-color);
  }
}

.btn-secondary {
  background-color: var(--surface6);
  color: var(--text1);

  &:hover {
    background-color: var(--surface5);
  }
}

.btn-group {
  padding: 0.25rem !important;

  .btn {
    line-height: 1.0;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }

  :not(.active):hover {
    transform: translateY(-0.06rem);
  }

  :not(.active) {
    background-color: var(--surface1);
  }
}

em {
  color: var(--text3);
}

input[type="radio"],
input[type="checkbox"] {
  width: 1em;
  height: 1rem;
  accent-color: var(--text3);
}

hr.divider {
  height: 0.15rem;
  margin-top: 0.25rem;
  background-color: var(--surface7);
}

p {
  font-size: 1.25em;
}

// Bootstrap Popovers
//  Note that some CSS properties are also modified.
//  See the bs-popover-* properties at the beginning of the file.
.popover {
  .popover-body {
    border-color: var(--text2);
    background-color: var(--text2);
    border-radius: 0.5rem;
  }

  img {
    width: 62.50rem;
  }
}

strong {
  color: var(--text4);
}

i,
span {
  display: inline-block;
}

.selected, .is-selected {
  background-color: var(--selected-bg) !important;
  border-radius: var(--border-radius1);
  color: var(--selected-color) !important;

  a {
    color: var(--selected-color) !important;
  }

  .text-secondary {
    color: var(--selected-secondary-color) !important;
  }

  .yt-hover-target {
    background-color: var(--selected-bg);
  }

  // The first and last cells of any selected row are highlighted
  @extend .hoverable-cells;
}

.text4 {
  color: var(--text4);
}

.text5 {
  color: var(--text5);
}

.text-name {
  color: var(--text1);
}

.text-value {
  color: var(--text4);
}

.icon-hover {
  color: var(--text3);
  &:hover {
    transform: scale(1.1);
  }
}

//
// Lists
//

.list-group-item {
  background-color: var(--surface2);
  color: var(--text1);
}

.list-group-item-secondary {
  background-color: var(--surface1);
  color: var(--text3);
}

// A list with only interior borders
.interior-borders {
  li:first-child {
    border-top: none;
  }

  li:last-child {
    border-bottom: none;
  }

  li {
    border-right: none;
    border-left: none;
  }
}

// A list with separate a "name" and "value"
.item-name {
  color: var(--text2);
}

.item-value {
  padding-left: 0.5rem;
  color: var(--text4);
}

//
// Helper classes
//

.w-20 {
  width: 20%;
}

.mw-50 {
  min-width: 50%;
}

.vh-50 {
  height: 50vh;
}

.vh-95 {
  height: 95vh;
}

.text-emphasis {
  color: var(--text1);
}

.text-notice {
  color: var(--text-notice);
}

.text-small {
  font-size: 0.75rem;
}

.text-secondary {
  color: var(--text3);
}

.z-index-positive {
  z-index: 10 !important;
}

// CSS Transitions
.slide-fade-enter-active {
  transition: all 1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.0s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.highlight-box {
  padding: 1em;
  background: var(--surface1);
  border-radius: 0.7rem;
  box-shadow: 0.06rem 0.06rem 0.31rem var(--surface4), -0.06rem -0.06rem 0.31rem var(--surface4);
}

.notice-big {
  background-color: var(--surface5);
  color: var(--notice-color);

  div.message {
    color: var(--notice-title-color);
  }

  .message {
    color: var(--text1);
  }
}

.list-header-border {
  border-bottom: 0.25rem solid var(--surface7);
}

.circle {
  display: flex;
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  background-color: $info;
  border-radius: 1.5rem;
  color: var(--text1);
}

.button-icon {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-color: var(--text1);
  background-color: var(--surface2);
  border-radius: 0.375rem;
  box-shadow: 0 0.25rem 0.37rem var(--surface7), 0 0.06rem 0.18rem var(--surface7);

  &:hover {
    transform: translateY(-0.06rem);
  }
}

// Add an "on hover" glow effect to images, noteably SVG font-awesome icons
.glow {
  &:hover {
    filter: drop-shadow(0.12rem 0.12rem 0.62rem var(--text3)) drop-shadow(-0.12rem -0.12rem 0.62rem var(--text3));
  }
}

.zoom {
  transition: transform 1s;

  &:hover {
    filter: drop-shadow(0.12rem 0.12rem 0.62rem var(--text3-transparent)) drop-shadow(-0.12rem -0.12rem 0.62rem var(--text3-transparent));
    transform: scale(1.1);
  }
}


.icon-disabled {
  color: var(--text-disabled);
}

// Lists

.list-with-counts {
  border-radius: var(--border-radius1);
  a {
    color: var(--text1);
  }

  &:hover {
    background-color: var(--hover-bg);
    cursor: pointer;
  }

  span.badge {
    background-color: var(--surface5);
  }
}

li {
  &.important {
    background-color: var(--surface3);
  }
}

a.tag {
  &:link {
    color: var(--text1);
  }

  &:visited {
    color: var(--text1);
  }

  &:hover {
    color: var(--text1);
  }
}

// Modals

.modal-header {
  color: var(--text3);
  font-size: 1.5rem;

  // The close button for Bootstrap modals
  .close {
    color: var(--text1);
  }
}

.modal-content {
  border: 0.12rem solid var(--text4);
  background-color: var(--surface6);
}

.pagination {
  .page-item.disabled {
    .page-link {
      background-color: var(--surface6);
    }
  }

  .page-link {
    background-color: var(--surface5);
    color: var(--text1);

    &:hover {
      background-color: var(--surface1);
    }
  }
}

.pagination-divider {
  display: flex;
  align-items: center;
  background-color: $pagination-bg;

  div {
    background-color: var(--surface7);
  }
}

.scrollable-panel-container {
  height: 92vh;
}

// Show vertical scrollbar if necessary.
// Hide horizontal scrollbar.
.scrollable-panel {
  overflow-x: hidden;
  overflow-y: auto;
}

// Hide all scrollbars by default.
// Reveal vertical scrollbar on hover.
.scrollable-panel-scrollbar-hover {
  overflow: hidden;

  &:hover {
    // "overlay" not supported by Firefox
    @supports not( -moz-appearance:none ) {
      overflow-y: overlay;
    }
    @supports ( -moz-appearance:none ) {
      overflow-y: auto;
    }
  }
}

// Floating Vue (tooltips)
.v-popper__popper .v-popper__wrapper {
  box-shadow: rgba(255,255,255,0.2) 0px 0.43rem 1.8rem 0px;

  .v-popper__inner {
    padding-bottom: 0.75rem;
    background-color: var(--tooltip-bg);
    color: var(--tooltip-color);

    // These are generated by Python's markdown module
    p {
      margin-bottom: 0;
    }
  }
}

// Oruga switches

.o-switch__check--checked {
  background: var(--toggle-button-color-toggled) !important;
}

.o-switch__label {
  margin-left: 1rem !important;
}

// Oruga tables

.is-invisible {
  display: none !important;
}

.o-table {
  border-collapse: collapse !important;

  .icon {
    transition: transform 150ms ease-out, opacity 86ms ease-out;
    &.is-desc {
      transform: rotate(180deg);
    }
    &.is-expanded {
      transform: rotate(90deg);
    }
  }

  thead {
    border-bottom: 0.25rem solid var(--surface5);
  }

  th {
    padding: 0.6rem;
    border-top: none;
    color: $primary;

    .o-table__th__sort-icon {
      color: var(--text2);
    }
  }

  td {
    padding: 0.6rem;
    vertical-align: middle;
  }

  tr {
    border-bottom: 1px solid var(--surface6);
  }

  &.o-table--hoverable tbody tr {
    @extend .hoverable-cells;
  }
}

.o-table__tr--selected {
  @extend .selected;
}

.table-borderless td,
.table-borderless th {
  padding: 0.6rem;
  border: 0;
}

.hoverable-cells {
  &:hover {
    background-color: var(--hover-bg);
    td:nth-child(1) {
      border-bottom-left-radius: var(--border-radius2);
      border-top-left-radius: var(--border-radius2);
    }
    td:nth-last-child(1) {
      border-bottom-right-radius: var(--border-radius2);
      border-top-right-radius: var(--border-radius2);
    }
  }
}

.hoverable {
  border-radius: var(--border-radius2);

  &:hover {
    background-color: var(--hover-bg);
  }
}

.list-delete {
  display: none;
  color: var(--text1);

  &:hover {
    transform: scale(1.1);

    @extend .close-button;
  }
}

.fader::after {
  position: absolute;
  z-index: 10000;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4em;
  background-image: linear-gradient(to bottom, var(--surface2-transparent), var(--surface2) 90%);
  content: "";
  pointer-events: none;
}

.toast-wrapper {
  z-index: 300;
  line-height: 1.5rem;

  .toast-header {
    background-color: var(--surface6);
    color: var(--text1);

    button {
      color: var(--text1);
    }
  }

  .toast-body {
    background-color: var(--surface1);
  }

  &.danger {
    .toast-header {
      background-color: $danger;
      color: $black;
      strong {
        color: $black;
      }
    }
    .toast-body {
      background: tint-color($danger, 50%);
      color: $black;
      a {
        color: $danger;
      }
    }
  }

  &.warning {
    .toast-header {
      background-color: $warning;
      color: $black;
    }
  }
}

input.form-control,
select.form-control,
textarea.form-control {
  border: 0.06rem solid var(--surface1);
  background-color: var(--form-bg);
  color: var(--form-color) !important;

  &:hover {
    border-color: var(--form-border-color-hover);
  }

  &:focus {
    border-color: var(--form-border-color-hover);
    background-color: var(--form-bg-focus);
    color: var(--text4);
  }

  &:disabled {
    border: none;
    background-color: var(--form-bg-disabled);

    &:hover {
      cursor: not-allowed;
    }
  }
}

.input-group-text {
  border: 0.06rem solid var(--surface6);
  background-color: var(--surface6);
  border-radius: var(--border-radius1);
  color: var(--text1);
}

.tag, .ti-input .ti-tag {
  padding: 0 0.3rem 0.18rem 0.3rem !important;
  border: 0.12rem solid var(--surface5);
  margin: 0 0.12rem 0 0.12rem !important;
  background-color: var(--surface6) !important;
  border-radius: 1.5rem !important;
  color: var(--text4) !important;
  line-height: 1.5rem;
  white-space: nowrap;

  &:hover {
    background-color: var(--text4) !important;
    color: var(--surface6) !important;
    text-decoration: none;

    strong, svg {
      color: var(--surface6) !important;
    }
  }

  svg:hover {
    color: var(--surface1) !important;
  }
}

// From Bootstrap scss/_variables.scss
.card-box-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 0.25rem 0.37rem, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -0.18rem 0.31rem;
}

// Base Template

#content {
  width: 100%;
  min-height: 100vh;
  transition: all 0.3s;
}

// Use this to reveal hidden targets on-hover
.hover-reveal-target:hover {
  .hover-reveal-object {
    display: flex !important;
    height: var(--dropdown-height);
  }
}

// Dropdown Menus

.dropdown-menu {
  background-color: var(--surface6);
}

.dropdown:not(.top-drop-down) {
  min-width: var(--dropdown-height);
  min-height: var(--dropdown-height);
}

.hover-target:hover {
  .dropdown > div {
    display: flex !important;
  }
}

.dropdown-menu-container {
  height: var(--dropdown-height);
}

.dropdown-menu-container-width {
  width: 3.75rem;
}

.dropdown {
  .dropdown-menu {
    top: 0.6rem !important;
    transition: all 500ms cubic-bezier(0.53, 2, 0.36, 0.85);
  }
  li {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}

.dropdown-item {
  border-radius: var(--border-radius1);
  color: var(--text1);

  span {
    svg {
      width: 1rem !important;
    }
  }

  &:hover {
    background-color: var(--surface2) !important;
    color: var(--text1);
  }
}

// Add this to any div that needs to be at least as tall
//  as the dropdown target, to avoid on-hover jitter
.dropdown-height {
  min-height: var(--dropdown-height);
}

// Extra content after the dropdown item's name
.dropdown-item-extra {
  width: 1.25rem;
  height: 1.25rem;
  padding-top: 0.25rem;
  background-color: var(--surface5);
  border-radius: 50%;
  color: var(--dropdown-item-extra-color);
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 0.75rem;
  text-align: center;
}

.option-icon {
  display: flex;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 1.2rem;
  &:hover {
    background-color: var(--hover-bg);
    cursor: pointer;
    transform: translateY(-0.06rem);
  }
  &.enabled {
    background-color: var(--selected-bg);
  }
}

// The round, target point that spawns the dropdown menu below it
.dropdownmenu {
  display: flex;
  width: var(--dropdown-height);
  height: var(--dropdown-height);
  margin-left: auto; // Push to the right
  font-size: 1rem;

  &.calendar-date-format-menu {
    // If we add this class, don't push to the right
    margin-left: none;
  }

  &:hover {
    background-color: var(--hover-bg);
    border-radius: 100%;
  }
}

// Error pages

.response-code {
  margin-bottom: 2rem;
  font-size: 6.25rem;
  font-weight: bold;
}

// Login page

.login-body {
  background-image: url("/static/img/header_cyber.jpg");
  background-size: cover;
}

#login-quote {
  margin-right: auto;
  margin-left: auto;
  text-align: center;

  small {
    padding: 0px;

    em {
      color: #8291e5;
    }
  }
}

.jumbotron {
  width: 37.50rem;
  height: 43.75rem;
  border: 0.12rem solid var(--text1);
  border-radius: 1.5rem;
  opacity: 0.9;

  .input-group-text {
    background-color: var(--text4);
  };

  > div {
    background-image: url("/static/img/bordercore-logo-login.png");
    background-position: center -90px;
    background-repeat: no-repeat;
    background-size: 32rem 32rem;
  }

  form {
    top: 18.75rem;
    right: 4rem;

  }
}

// Help

#modal-body-help {
  h1 {
    color: var(--text5);
    font-size: 1rem;
    text-transform: uppercase;
  }
  strong {
    color: var(--text3);
  }
  em {
    color: var(--text-em);
  }
}

//
// Tables
//

.table {
  border-color: var(--surface6);
  color: var(--text1);

  th {
    color: $primary;
  }
}

// Eliminate the focus effect when selecting a row or column
.b-table tr:focus,
.b-table th:focus {
  outline: 0;
}

.table-note {
  color: var(--text3);
  font-size: 0.75rem;

  p {
    margin-bottom: 0;
  }
}

table.b-table th {
  border-top: 0;
  color: var(--text3);
}

.table-hover tr.selected:hover a {
  background-color: rgba(0, 0, 0, 0.075);
  transition: none;
}

.table-borders table,
.table-borders td {
  padding: 0.6rem;
  border: 0.06rem solid var(--surface5);
}

.table-borders th {
  padding: 0.6rem;
  border-bottom: 0.18rem solid var(--surface5);
}

.table-colors {
  table {
    margin: 2rem 0 2rem;
  }
  table thead {
    color: $success;
  }
  table, th, td {
    border: 0.12rem solid $secondary;
  }
  table tbody {
    color: $primary;
  }
}

// Animated buttons

.glyphicon-refresh-animate {
  -animation: spin 0.7s infinite linear;
  -webkit-animation: spin2 0.7s infinite linear;
}

@-webkit-keyframes spin2 {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  from { transform: scale(1) rotate(0deg); }
  to { transform: scale(1) rotate(360deg); }
}

// Bootstrap modifications

.breadcrumb {
  padding-left: 2.25rem;
  background-color: transparent;
}

// Modified from bootstrap/scss/_nav.scss
.nav-tabs, .nav {
  border-bottom-color: var(--surface5);

  &:not(.nav-vertical):hover, &:focus {
    color: var(--text5);
  }

  &.nav-vertical {
    border: none;
  }

  .nav-link {
    border: none;

    &:hover {
      color: var(--text5);
    }

    &.active {
      border-bottom: 0.18rem solid var(--text5);
      background-color: var(--surface6);
      color: var(--text1);

      // Modals have a different background, so we need the
      //  active tab to also have a different background
      &.tab-modal {
        background-color: var(--surface1);
      }
    }
  }
}

.card-body {
  @extend .card-box-shadow;
  position: relative;
  z-index: 1;
  flex-grow: 0;
  padding: 1.25rem;
  margin: 0 0.5rem 1rem 0.5rem;
  background-color: var(--surface2) !important;
  border-radius: 0.4rem;

  .side-panel {
    width: 18.75rem;
  }

  // Negate the default background for this class so that
  //  the transparency of the parent will take effect
  .list-group-item {
    background: none;
  }
}

@supports (backdrop-filter: blur(1.56rem) brightness(170%)) {
  .backdrop-filter {
    backdrop-filter: var(--eye-candy);
    background: var(--surface1-transparent) !important;
  }
}

// Forms
.form-error {
  margin-left: 0.6rem;
  color: var(--text-error);
  font-weight: bold;
}

.form-section {
  color: var(--text3);
  font-size: larger;
  font-weight: bold;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--form-color) !important;
  opacity: 1 !important; /* Firefox */
}

// Used when you want to display text instead of a form field
.form-no-input {
  padding: 0.4rem 1.25rem;
}

.input-group .highlight-changed-field {
  color: var(--text5) !important;
}

.highlight-changed-field.color-transition {
  color: var(--text4) !important;
  transition: 2s;
  transition-delay: 1s;
}

//
// Markdown
//

// This primarily applies to markdown generated by org-mode exported files.
//  Note the `white-space` selector to preserve newlines.

blockquote > p {
  padding: 0.5rem 0 0.5rem 0.5rem;
  border-left: 0.25rem solid var(--text3);
  margin: 0.5rem;
  background-color: var(--surface6);
  white-space: pre-line;
}

// Inline code. Exclude code in pre blocks, since
//  that is handled by prism.js

*:not(pre) > code {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  background-color: var(--surface6);
  color: var(--text6);
}

// Top Title

#top-title * {
  padding-left: var(--grid-margin);
  background: linear-gradient(
    to right,
    var(--title-gradient-start),
    var(--title-gradient-end)
  );
  -webkit-background-clip: text;
  font-size: 2rem;
  line-height: 2.7rem;
  -webkit-text-fill-color: transparent;
}

//
// Top Search
//

#top-search {
  position: absolute;
  z-index: 1000;
  top: -1.25rem;
  right: 0.25rem;
  width: 25rem;
  background-color: var(--surface2) !important;

  .multiselect__tags {
    box-shadow: #212529 1rem 1rem 3rem 1rem, #212529 -1rem -1rem 3rem 1rem;
  }
}

#top-bar {
  @extend .card-box-shadow;
  margin: var(--grid-margin) var(--grid-margin) var(--grid-margin) var(--grid-margin);
  background-color: var(--surface1);
  border-radius: 0.4rem;

  #top-search-bar {
    right: 16rem;
  }
}

//  Set the z-index to something above 50 to be visible if using
//  with Vue-multiselect, which sets its z-index to this value.
#top-search-filter {
  position: absolute;
  z-index: 100;
  top: 0.325rem;
  right: 0.5rem;
  text-align: center;

  &.tag {
    padding: 0 0.6rem 0 0.6rem !important;
  }

  i {
    position: relative;
    top: .12rem;
  }
}

.top-search-object-type {
  color: var(--text3);
}

#top-search-filter-options {
  position: absolute;
  z-index: 1000;
  width: 23rem;
  background-color: var(--surface2);
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0 1.37rem 4.37rem 0.25rem;

  svg {
    color: var(--text3);
  }

  .top-search-filter-icon {
    width: 1.5rem;
  }

  .tag-list:hover {
    background-color: var(--surface5);
  }
}

//
// Homepage
//

#recent_bookmarks {
  scrollbar-gutter: stable;
}

//
// Bookmarks
//

.hover-tag {
  background-color: var(--surface2);
  color: var(--text5);
}

#bookmark-list-container {
  min-height: 100%;
  border-radius: var(--border-radius2);
  scrollbar-gutter: stable;

  ul {
    background-color: var(--surface2);
  }
}

.bookmark-list {
  @extend .hoverable-cells;

  .drag-handle {
    width: 1rem;
    color: var(--text2);
    cursor: grab;
  };

  .date {
    width: 10rem;
    text-align: right;
    vertical-align: text-bottom;
    white-space: nowrap;
  }

  .thumbnail {
    vertical-align: text-bottom;
  }

  .link {
    vertical-align: text-bottom;
  }
}

.back-reference-question {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: var(--text7);
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
}

#bookmark-search-form {
  background-color: var(--surface1);
  border-radius: var(--border-radius2);

  form {
    width: 18.75rem;
  }
}


#bookmark-select-add {
  height: 2.5rem;
}

.monospaced {
  font-family: Courier, sans-serif;
}

// The "has-search" rules provide space for a Font Awesome
//  search icon inside a search input box

.has-search {
  // Specifies the distance between the search icon and where
  //   you start typing your search query
  .form-control, .multiselect__tags {
    padding-left: 2.5rem !important;
  }

  // Absolutely position a search icon inside a search box.
  //
  //  Set the z-index to something above 50 to be visible if using
  //  with Vue-multiselect, which sets its z-index to this value.
  svg[data-icon="search"] {
    position: absolute;
    z-index: 100;
    top: 0.75rem;
    left: 0.75rem;
    display: block;
    line-height: 2.375rem;
    pointer-events: none;
    text-align: center;
  }
}

#bookmark-search-filter {
  font-size: 0.8rem;
}

.yt-hover-target {
  right: 1rem;
  bottom: 1rem;
  padding-left: 0.5rem;
  background-color: var(--hover-bg);
  // Note: see additional selector underneath .selected, above
}

//
// Search
//
.tag-search {
  width: 25rem;
}

.semantic-search {
  svg[data-icon="search"] {
    z-index: 98;
  }
}

// Use this for cancel icons inside search input fields
.search-input-cancel {
  position: absolute;
  top: 0.20rem;
  right: 0.6rem;

  svg:hover {
    color: var(--text1) !important;
  }
}

.search-input {
  width: 10rem;
}

.search-result {
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  border-bottom: 0.1rem solid var(--surface3);
  border-radius: var(--border-radius1);

  &:hover {
    background-color: var(--surface2);
  }

  .search-result-icon {
    width: 2rem;
    min-width: 2rem;
  }

  .title {
    color: var(--link-color);
  }

  h4 {
    .favorite {
      font-size: 1rem;
    }

    small {
      color: var(--surface5);
      font-size: 0.75rem;
    }
  }

  h5 {
    color: var(--text1);
    font-size: 1rem;

    em {
      color: var(--text-em);
    }
  }

  .truncate-text {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
  }

  // These are generated by Python's markdown module
  p {
    padding-right: 1rem;
    margin-bottom: 0;
    font-size: 1.5rem;
  }

  &.grid {
    width: 33%;
    border-bottom: 0;
    @media screen and (max-width: 1200px) {
      width: 50%;
    }
  }
}

.search-result-date {
  color: var(--text2);
}

.search-result-header {
  color: var(--text3);
}

// Fit images in a specifically sized box
//  while preserving their aspect ratio
.search-result-music {
  width: auto;
  max-width: 9.37rem;
  height: auto;
  max-height: 9.37rem;
}

.search-todo-date {
  color: var(--text2);
  font-size: 0.75rem;
  font-weight: normal;
}

.bookmark-dropdown {
  width: 2.5rem;
}

.search-suggestion {
  overflow: hidden;
  width: 100%;
  max-width: 23rem;
  padding: 0.6rem;
  border-radius: var(--border-radius1);
  text-overflow: ellipsis;
  white-space: nowrap;

  a:hover {
    background-color: var(--surface3) !important;
  }

  svg {
    margin-top: 0.1rem;
  }

  &:hover {
    background-color: var(--hover-bg);
    cursor: pointer;
  }
}

.search-splitter {
  width: 100%;
  padding: 1rem 0.6rem 1rem 0.6rem;
  border-color: var(--search-splitter-bg) !important;
  border-top: 0.06rem solid var(--surface3);
  background-color: var(--search-splitter-bg);
  border-radius: var(--border-radius1);
  color: var(--search-splitter-color);
  cursor: auto;
  font-size: larger;
  font-weight: bold;
}

//
// Blobs
//

.blob-list {
  .blob-content {
    overflow: hidden;
    height: 18.75rem;
    font-size: 0.25rem;
    line-height: 1rem;

    p {
      margin-bottom: 0.5rem;
    }
  }

  .blob-info {
    font-size: 0.6rem;
  }

  &:hover {
    background-color: var(--hover-bg);
  }
}

.blob-collection-note {
  position: absolute;
  bottom: 0rem;
  left: 1rem;
  color: var(--text4);
}

#blob-metadata {
  right: 0;
}

#blob_subtitle {
  color: var(--text4);
}

.tree-menu {
  ul {
    padding-left: 1em;
    line-height: 1.5em;

    li::marker {
      color: var(--text6);
      content: "• ";
    }
  }

  a {
    color: var(--text6);
  }
}

.tree-folder a {
  color: var(--text4);
  font-weight: bold;
}

.hide-list-element {
  padding-left: 0;
  list-style-type: none;
}

#overlay {
  position: fixed;
  z-index: 150;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
  background-color: $black;
}

#file-upload {
  width: 80%;
  padding: 3rem;
  border: 0.06rem solid var(--text2);
  background: linear-gradient(180deg, var(--blob-file-upload-gradient-start) 29%, var(--blob-file-upload-gradient-end) 90%);
  background-color: var(--surface6);
  border-radius: var(--border-radius1);

  #text-divider {
    color: var(--text2);
    font-size: 1rem;
  }

  #filename {
    color: var(--text2);
    font-size: 1rem;
  }
}

.related-draggable {
  background-color: var(--surface6) !important;
}

.slide-show {
  position: fixed;
  z-index: 200;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  margin: auto;
  object-fit: contain;
}

#blob-note {
  p {
    margin-bottom: 0;
  }
}

#blob-detail-content {
  background-color: var(--surface1);
  border-radius: 0.4rem;
}

.blob-content {
  em {
    color: var(--text-em);
  }

  h1 {
    color: var(--text3);
    font-size: 2rem;
  }

  h2 {
    color: hsl(var(--text3-hue) var(--text3-saturation) calc(var(--text3-lightness) - 30%));
    font-size: 1.75rem;
  }

  h3 {
    color: hsl(var(--text3-hue) var(--text3-saturation) calc(var(--text3-lightness) - 40%));
    font-size: 1.5rem;
  }

  h4 {
    color: hsl(var(--text3-hue) var(--text3-saturation) calc(var(--text3-lightness) - 50%));
    font-size: 1.25rem;
  }

  h5 {
    color: hsl(var(--text3-hue) var(--text3-saturation) calc(var(--text3-lightness) - 60%));
    font-size: 1rem;
  }
}

.blob-detail-cover-image {
  height: 85vh;

  img {
    cursor: pointer;
    object-fit: contain;
    object-position: left top;
  }
}

.blob-detail-cover-image-with-content {
  width: 50%;
  padding-left: 1rem;
  margin-left: 1rem;
  float: right;

  img {
    cursor: pointer;
  }
}

#blob-metadata-button {
  width: 2.5rem;
}

.metadata-icon {
  color: var(--text1);
}

.recent-blobs {
  min-width: 32rem;
  .icon {
    width: 4rem;
    height: 4rem;
    color: var(--text3);
  }

  li {
    div:first-child {
      width: 5rem;
      color: var(--text4);
    }
    div:first-child.recent-doctype-node {
      color: var(--text4-1);
    }
    div:first-child.recent-doctype-note {
      color: var(--text4-2);
    }
  }

  li:hover, a:hover {
    background-color: var(--surface3) !important;
  }
}

@media screen and (max-width: 1200px) {
  #linked-blob {
    flex-direction: column;
  }
}

#blob-import-icon {
  width: 6.25rem;
}

div #dateapp {
  width: 10rem;
}

#date_year {
  width: 7rem;
}

#blob-tag-list {
  line-height: 2;
  word-break: break-all;
}

#blob-options {
  font-size: 0.75rem;
}

#section-cover-image {
  div div:nth-child(1) {
    padding-right: 8rem;
    font-size: 1.25rem;
  }

  input {
    max-width: 4rem;
  }
}

#chatbot {
  position: fixed;
  z-index: 1000;
  bottom: 1rem;
  width: 100%;

  .chatbot-container {
    background-color: var(--chatbot-container-bg);
    border-radius: var(--border-radius1);
    line-height: 1.75rem;
  }

  .chatbot-messages {
    overflow: scroll;
    height: 40rem;
    background-color: var(--surface6);
  }

  .chatbot-mode {
    width: 10rem;
  };

  .chatbot-user {
    color: var(--text1);
  }

  .chatbot-assistant {
    color: var(--text4);
  }

  .chatbot-waiting {
    color: var(--text3);
  }
}

//
// Bookshelf
//
.book_count {
  color: var(--text4);
  font-size: 2rem;
  font-weight: bold;
}

//
// Notes
//

.note-search-result {
  .note-content {
    overflow: hidden;
    height: 18.75rem;
    font-size: 1rem;

    h1,
    .h1 {
      font-size: 3rem * 0.3;
    }

    h2,
    .h2 {
      font-size: 2.5rem * 0.3;
    }

    h3,
    .h3 {
      font-size: 2rem * 0.3;
    }

    h4,
    .h4 {
      font-size: 1.40625rem * 0.3;
    }

    h5,
    .h5 {
      font-size: 1.171875rem * 0.3;
    }

    h6,
    .h6 {
      font-size: 0.9375rem * 0.3;
    }
  }

  .selected .fader::after {
    background-image: linear-gradient(to bottom, var(--surface5-transparent), var(--surface5) 90%) !important;
  }

  > li {
    border-top: 0.06rem solid var(--text3);

    &:hover {
      background-color: var(--hover-bg);

      .fader::after {
        // Note: the apparent "mismatch" between the two colors is intentional
        background-image: linear-gradient(to bottom, var(--surface5-transparent), var(--hover-bg) 90%);
      }
    }
  }
}

.expanded-note {
  max-height: none !important;
}

.note-content-truncated {
  overflow: hidden;
  max-height: 31.25rem;
}

//
// Todo
//

.tag-list {
  border-radius: var(--border-radius1);

  a {
    color: var(--text1);
  }

  &:hover {
    background-color: var(--hover-bg);
    cursor: pointer;
  }

  span.badge {
    background-color: var(--surface5);
  }
}

.todo-col-manual-sorting {
  width: 7rem;
  cursor: grab;
}

.todo-col-priority {
  width: 7rem;
}

.todo-col-date {
  width: 8rem;
  white-space: nowrap;
}

.col-action {
  width: 4rem;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

//
// Fitness
//

.fitness-col-muscle-group {
  width: 10rem;
}

.fitness-col-schedule {
  width: 9rem;
}

.fitness-col-new-workout-data {
  width: 6rem;
}

#fitness-has-note {
  margin-left: 5rem;
}

#fitness-schedule-d-o-t-w {
  width: 7rem;
}

.overdue {
  td, a {
    background-color: var(--fitness-overdue-bg);
    color: var(--fitness-overdue-color);

    span {
      color: var(--text3);
    }
  }
}

.last-active {
  width: 17rem;
}

#exercise-detail-chart {
  height: 25rem !important;
}

#muscles-targeted .small {
  font-size: 0.75rem;
}

//
// Sortable
//

.wide-list {
  span {
    width: 100%;
  }

  li {
    // Chrome adds unwanted corners to the edges of
    //   rounded elements during drag and drop, so only
    //   use rounded borders for Mozilla.
    @-moz-document url-prefix() {
      border-radius: 0.5rem;
    }

    &:hover {
      background-color: var(--hover-bg);

      .list-delete {
        display: block;
      }
    }
  }
}

//
// Node
//

.node-bookmark {
  height: 3.5rem;
}

.node-list-stats {
  font-size: 0.75rem;
}

.node-name {
  color: var(--text4);
}

.node-note {
  font-size: 0.87rem;
}

.node-color {
  height: 2rem;
  border-radius: 0.25rem;

  &.selected-color {
    border: 0.12rem solid var(--text1);
  }
}

.node-color-1 {
  background-color: var(--node-color-1) !important;
  transition: 0.3s ease;
  &:hover {
    background-color: var(--node-color-1-tinted) !important;
  }
}

.node-color-2 {
  background-color: var(--node-color-2) !important;
  transition: 0.3s ease;
  &:hover {
    background-color: var(--node-color-2-tinted) !important;
  }
}

.node-color-3 {
  background-color: var(--node-color-3) !important;
  transition: 0.3s ease;
  &:hover {
    background-color: var(--node-color-3-tinted) !important;
  }
}

.node-color-4 {
  background-color: var(--node-color-4) !important;
  transition: 0.3s ease;
  &:hover {
    background-color: var(--node-color-4-tinted) !important;
  }
}

.node-column {
  width: 33%;
}

.node-object-note {
  color: var(--text2);
  font-size: 0.75rem;
}

.node-url a {
  color: var(--text3);
}

.node-drag-over {
  border-radius: 0.4rem;
  box-shadow: rgba(0, 0, 0, 0.56) 0 1.37rem 4.37rem 0.25rem;
}

.node-node-misc-one-line {
  height: 2rem;
}

.node-node-misc-two-lines {
  height: 4rem;
}

//
// Metrics
//

.metric-test-result-popover {
  width: 50rem;
  max-width: 100%;
}

//
// Drill
//

#drill-pinned-tags .list-group-item {
  background-color: unset;

  &:hover {
    background-color: var(--hover-bg);
  }
}

#icon-list {
  padding-right: 2.25rem;
}

.favorite {
  color: #f00;
}

.question-disabled {
  color: $warning;
  font-weight: bold;
}

.progress-circle {
  circle.circle-partial {
    stroke: var(--drill-progress-circle-partial);
  }

  circle.circle-full {
    stroke: var(--drill-progress-circle-full);
  }

  text {
    fill: $primary;
  }
}

#modal-study {
  :disabled {
    border: 0.667px solid transparent;
  }

  input.form-control {
    height: calc(1.5em + 0.75rem + 0.5rem);
    border-radius: 0.25rem;
    font-size: 1.0rem;
  }

  #tag-name {
    width: 20rem;
  }

  #study-random-count {
    width: 5rem;
  }

  #search-term {
    width: 45%;
  }

  #question-filter {
    width: 20rem;
  }
}

//
// Collections
//

.collection-container {
  @extend .zoom;
  width: calc(var(--collection-list-item-width) + 3rem);
}

.collection {
  overflow: hidden;
  width: var(--collection-list-item-width);

  img {
    width: var(--collection-list-item-width);
    height: var(--collection-list-item-width);
    border-radius: 0.25rem;
  }

  .collection-cover-container {
    bottom: -1.65rem;
    left: 0.01rem;
    display: block;
    width: var(--collection-list-item-width);
    height: 1.65rem;
    background-color: rgba(50, 50, 50, 0.5);
    font-size: smaller;
    transition: bottom 250ms;
  }

  &:hover {
    .collection-cover-container {
      bottom: -0.01rem;
      display: block;
    }
  }
}

.collection-item {
  position: relative;

  .collection-item-name {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .collection-item-delete {
    position: absolute;
    top: 0.35rem;
    right: 0.35rem;
    display: none;
  }

  &:hover {
    .collection-item-delete {
      display: block;
    }
  }
}

.collection-sortable li {
  width: 12.5rem;
}

hr.collection-list-divider {
  height: 0.3rem;
  margin-top: 0;
  background-color: var(--surface7);
}

#collection-list {
  border-radius: 0.6rem;
}

.collection-drag-over {
  background-color: var(--surface3);
}

.collection-slide-show-section {
  background-color: var(--surface6);
  border-radius: 0.7rem;
}

//
// Feeds
//

#feed-status {
  font-size: 1rem;
}

.feed-draggable {
  background-color: var(--surface1) !important;
}

//
// Preferences
//

#id_pinned_tags li {
  margin-bottom: 0.25rem !important;
}

//
// Music
//

.music-nav {
  min-height: 2rem;
}

// Create album
#create-album-container {
  padding: 1rem;
  border: 0.06rem solid #000;
  margin: 0 3rem;
  background-color: var(--surface5);
  background-image: radial-gradient(50% 50% at 50% 50%, rgba(68, 9, 45,.48) 0%, rgba(68, 9, 45,0) 100%);
  border-radius: 1rem;
  transition: all 2s;

  .input-group label.btn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  #id_tags {
    width: 15rem !important;
  }

  table {
    th {
      padding: 0.5rem 2rem;
      border-bottom: 0.06rem solid var(--text2);
      font-weight: bold;
    }
    td {
      padding: 0.5rem 2rem;
    }
  }
}

// Audio player controls
.audio-player-wrapper {
  background-color: var(--media-background-color);
  border-radius: var(--border-radius2);
}

.media-control-bar {
  width: 100%;
  flex-flow: wrap;
}

media-controller {
  border-radius: var(--border-radius2);
}

media-time-display {
  color: var(--text1);
}

media-play-button {
  border-top-left-radius: var(--border-radius2);
}

media-playback-rate-button {
  border-bottom-left-radius: var(--border-radius2);
  color: var(--text1);
}

@media screen and (max-width: 1200px) {
  media-playback-rate-button {
    display: none;
  }
}

media-time-range {
  border-top-right-radius: var(--border-radius2);
}

media-volume-range {
  flex-grow: 1;
  border-bottom-right-radius: var(--border-radius2);
}

// Playlist modal
#modalCreateUpdate {
  input:disabled {
    border: 0.06rem solid transparent !important; // Eliminate jitter
  }
}

// Other

#album-list div {
  width: 12.31rem;
}

#album-song-list {
  td[data-label="Rating"] {
    width: 8rem;
    padding-right: 0;
  }
}

.dupe-song-note {
  font-size: 0.75rem;
}

.rating {
  color: var(--surface1-transparent);
}

.rating-no-hover {
  color: var(--text-disabled);
}

.rating-star-selected {
  color: var(--surface7);
}

.rating-star-hovered {
  color: var(--surface5);
}

tr.hover-target:hover {
  .rating-container {
    display: block !important;
  }
}

.v-md-editor-tall .v-md-editor {
  height: 69vh;
}

.v-md-editor {
  height: 100%;
  background-color: var(--form-bg) !important;
  color: var(--text1) !important;
  font-size: 1rem !important;

  .v-md-editor__main {
    border: 0.06rem solid var(--surface1);
    border-radius: 0.25rem;

    &:focus-within {
      border: 0.06rem solid var(--form-border-color-hover) !important;
      background-color: var(--form-bg-focus) !important;
      box-shadow: $input-btn-focus-box-shadow;
      caret-color: var(--text4);
      color: var(--text4);
    }

    &:hover {
      border: 0.06rem solid var(--form-border-color-hover);
      border-radius: 0 0 0.375rem 0.375rem;
    }
  }

  textarea {
    background-color: var(--form-bg) !important;
    color: var(--text1) !important;

    &:focus {
      background-color: var(--form-bg-focus) !important;
      caret-color: var(--text4);
      color: var(--text4);
    }
  }

  .v-md-editor__toolbar-item {
    color: var(--text1) !important;
    &:hover {
      color: var(--form-bg) !important;
    }
  }
}

.v-md-textarea-editor section {
  background-color: var(--form-bg);
}

// Tags
#tags-search {
  position: absolute;
  top: 2rem;
  right: .75rem;
  width: 12.5rem;
  box-shadow: 0.06rem 0.06rem 0.31rem var(--surface4), -0.06rem -0.06rem 0.31rem var(--surface4);
}

.related-tags {
  .count {
    font-size: 0.75rem;
  }
}

// SQL Playground
textarea.sql-input {
  background-color: var(--surface5);
  color: var(--text1);
}

// Vue Multiselect

.multiselect {
  // This mimics Bootstrap's input height
  min-height: 2.37rem !important;

  b {
    color: $primary;
  }
}

// The "caret" icon
.multiselect__select {
  color: var(--text1);

  // Eliminate default "caret" icon -- we will supply our own.
  &::before {
    content: none !important;
  }
}

.multiselect--disabled, .multiselect--disabled .multiselect__current, .multiselect--disabled .multiselect__select {
  background: none !important;
  color: $form-select-indicator-color !important;  // The caret on the right-hand side
  opacity: 1 !important;
  pointer-events: auto !important;

  .multiselect__placeholder {
    color: var(--form-color) !important;
  }

  &:hover {
    cursor: not-allowed !important;
  }

  .multiselect__tags {
    border: 0.06rem solid var(--form-bg-disabled) !important;
    &:hover {
      border: 0.06rem solid transparent !important;
    }

    background-color: var(--form-bg-disabled) !important;

    input {
      background-color: var(--form-bg-disabled) !important;
    }
  }
}

.multiselect__content-wrapper {
  border: 0 !important;
  overflow-x: hidden !important;
}

// This class is added on focus
.multiselect--active {
  // These selectors mimic Bootstrap's form-control class
  border-radius: $border-radius !important;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out !important;
  .multiselect__tags, input.multiselect__input {
    background-color: var(--form-bg-focus) !important;
  }
}

// Draw a border around the results, but only after results have
//  been found. Without the complicated CSS selector a border
//  would be shown around empty content.
.multiselect__content:has(li.multiselect__element:nth-child(2)) {
  border: 0.1rem solid var(--text6);
  border-radius: $border-radius !important;
}

.multiselect__option {
  background-color: var(--surface1) !important;
  color: var(--text2) !important;

  &:has(.search-splitter) {
    padding: 0 !important;
    background-color: var(--search-splitter-bg) !important;
  }
}

.multiselect__option--highlight {
  background-color: var(--hover-bg) !important;
}

.multiselect__element#null-0 {
  display: none;
}

.multiselect__placeholder, .multiselect__input::placeholder {
  display: inline-block !important;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  color: var(--text2);
  font-size: 1rem !important;
}

.multiselect:focus {
  background-color: var(--form-bg-focus) !important;
}

// Custom multiselect for tags input
.multiselect.tags-input {
  z-index: 99;
  .multiselect__tags {
    display: flex !important;
    padding-top: 0 !important;

    input {
      padding-top: 0 !important;
    }
  }
  .multiselect__placeholder {
    padding-top: 0.125rem !important;
  }
  // The "caret" icon
  .multiselect__select {
    height: auto !important; // Fix the rotated position
  }
}

.multiselect__tags {
  // This mimics Bootstrap's input height
  min-height: 2.37rem !important;
  padding-top: 0.12rem !important;

  border: 0.06rem solid var(--form-bg) !important;
  background-color: var(--form-bg) !important;
  border-radius: $border-radius !important;
  color: var(--form-color) !important;

  &:focus {
    background-color: var(--form-bg-focus) !important;
  }

  .multiselect__input, .multiselect__single {
    padding-top: 0.375rem !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
    background-color: var(--form-bg) !important;
    color: var(--form-color) !important;
  }

  &:hover {
    border: 0.06rem solid var(--text3) !important;
  }

  .multiselect__tags-wrap {
    display: flex !important;
    align-items: center !important;
  }

  .multiselect__tag {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    border: 0.06rem solid var(--form-bg) !important;
    margin-right: 0.37rem !important;
    margin-bottom: 0 !important;
    background-color: var(--vms-selected-bg) !important;
    border-radius: $border-radius !important;
    color: var(--vms-selected-color) !important;

    .multiselect__tag-icon::after {
      color: var(--vms-controls-color) !important;
    }

    .multiselect__tag-icon:hover::after {
      color: var(--vms-controls-color-hover) !important;
    }
  }
}

// vuejs-datepicker

.v3dp__datepicker {
  .selected {
    background-color: var(--vdp-bg-color) !important;
  }
  .v3dp__popout {
    // This should be higher than that of .multiselect.tags-input to avoid being obscured
    z-index: 100 !important;
  }
}

// vue-slicksort
.slicklist-item {
  position: relative;
  z-index: 1200;
};

.slicklist-helper, .slicklist-helper-bare {
  @extend .slicklist-item;

  .slicklist-list-item-inner {
    transform: rotate(2deg);
  }
}

.slicklist-list-item-inner {
  transition: background 0.2s, transform 0.2s;
}

.slicklist-helper .slicklist-list-item-inner {
  border: 0.05rem solid var(--text2);
  background-color: var(--surface1);
  border-radius: var(--border-radius1);
  box-shadow: 0.06rem 0.06rem 0.31rem var(--surface4), -0.06rem -0.06rem 0.31rem var(--surface4);
  transform: rotate(2deg);
}

// Sidebar

.sidebar-icon-1 {
  color: var(--sidebar-icon-color1);
}

.sidebar-icon-2 {
  color: var(--sidebar-icon-color2);
}

.sidebar-icon-3 {
  color: var(--sidebar-icon-color3);
}

.wrapper {
  display: flex;
  align-items: stretch;
  padding-left: 15rem;

  &.collapsed {
    display: flex;
    align-items: stretch;
    padding-left: 3.1rem;
  }
}

.sidebar-transition {
  transition: 0.3s ease;
}

#sidebar {
  .sidebar-header {
    padding: 1.3rem 0 0.6rem 0;
    color: var(--text3);
    font-size: 2rem;
  }

  .sidebar-header strong {
    display: none;
    font-size: 1.8em;
  }

  .first-item {
    margin-top: 2rem;
  }
}

.v-sidebar-menu {
  z-index: 100 !important;
  background: linear-gradient(180deg, var(--sidebar-gradient-start) 29%, var(--sidebar-gradient-end) 90%);

  &.vsm_collapsed {
    .first-item {
      margin-top: 6rem !important;
    }
  }

  .vsm--link {
    color: var(--sidebar-color) !important;

    &:hover {
      background-color: var(--sidebar-hover-bg) !important;
      border-radius: 0 !important;
      color: var(--sidebar-hover-color) !important;

      .vsm--icon {
        color: var(--sidebar-hover-color) !important;
      }
    }
  }

  // The open menu item
  .vsm--item_open .vsm--link_level-1 {
    background-color: var(--surface3) !important;
  }

  // Change the size of the icons
  .vsm--link_level-1 .vsm--icon {
    box-sizing: border-box;
    padding: 5px;
  }

  .vsm--link_level-2 {
    background-color: var(--sidebar-bg) !important;
    color: var(--sidebar-color) !important;
  }

  .vsm--link_active {
    background-color: var(--sidebar-active-bg) !important;
    border-radius: 0 !important;
    color: var(--sidebar-active-color) !important;

    .vsm--icon {
      color: var(--sidebar-hover-color) !important;
    }
  }

  .vsm--dropdown {
    background-color: var(--sidebar-bg) !important;

    .vsm--list {
      background-color: var(--sidebar-bg) !important;
      color: var(--sidebar-color) !important;
    }
  }

  .vsm--header {
    text-transform: none !important;
  }

  .vsm--icon {
    background-color: transparent !important;
  }

  .vsm--item {
    color: var(--sidebar-color) !important;
  }

  // This eliminates broken ::after icon glyph
  .vsm--arrow:after, .vsm--toggle-btn:after {
    content: "";
    font-family: "FontAwesome";
  }
}

// Copy button for code
.code-input,
pre[class^="language-"] {
  &:hover .copy-button {
    opacity: 1;
  }
}

.copy-button {
  position: absolute;
  z-index: 2; // Should be higher than the textarea for code console
  top: .3em;
  right: .2em;
  padding: 0 .5em;
  border: none;
  background: none;
  border-radius: .5em;
  color: #cfacd9;
  font-size: .8em;
  opacity: 0;
  text-shadow: 0 0 0.12rem #100c0f, 0 0 0.31rem #dc078e33, 0 0 0.62rem var(--text1);
  transition: opacity 0.3s ease-in-out;

  &:hover {
    filter: drop-shadow(0.12rem 0.12rem 0.62rem #f071c1) drop-shadow(-0.12rem -0.12rem 0.62rem #f071c1);
    opacity: 1;
  }

  &.console {
    right: 2.3em;
  }
}

//
// Effects
//

.animated-gradient-box {
  border: 3px solid #0000;
  animation: 8s rotate linear infinite;
  background: linear-gradient(#131219, #131219) padding-box, linear-gradient(
    var(--angle),
    #070707,
    #687aff
  ) border-box;
  border-radius: 12px;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

// Gallery
#gallery {
  li {
    padding: 0.62rem;
    margin: 0.62rem;
    background-color: var(--surface1);
  }

  #surfaces {
    li:nth-child(1) {
      background-color: var(--surface1);
    }
    li:nth-child(2) {
      background-color: var(--surface2);
    }
    li:nth-child(3) {
      background-color: var(--surface3);
    }
    li:nth-child(4) {
      background-color: var(--surface4);
    }
    li:nth-child(5) {
      background-color: var(--surface5);
    }
    li:nth-child(6) {
      background-color: var(--surface6);
    }
    li:nth-child(7) {
      background-color: var(--surface7);
    }
  }
  #texts {
    li:nth-child(1) {
      color: var(--text1);
    }
    li:nth-child(2) {
      color: var(--text2);
    }
    li:nth-child(3) {
      color: var(--text3);
    }
    li:nth-child(4) {
      color: var(--text4);
    }
    li:nth-child(5) {
      color: var(--text5);
    }
    li:nth-child(6) {
      color: var(--text6);
    }
    li:nth-child(7) {
      color: var(--text-disabled);
    }
  }
}
