$primary-color: #262c49 !default;
$base-bg: #10163a !default;

$item-color: #fff !default;

$item-active-color: null !default;
$item-active-bg: #7367f0 !default;

$item-open-color: #fff !default;
$item-open-bg: $primary-color !default;

$item-hover-color: null !default;
$item-hover-bg: rgba(darken($base-bg, 5%), 0.5) !default;

$icon-color: null !default;
$icon-bg: $base-bg;

$icon-active-color: null !default;
$icon-active-bg: $item-active-bg !default;

$icon-open-color: null !default;
$icon-open-bg: $item-open-bg !default;

$mobile-item-color: #fff !default;
$mobile-item-bg: $primary-color !default;
$mobile-icon-color: $mobile-item-color !default;
$mobile-icon-bg: $mobile-item-bg !default;

$dropdown-bg: lighten( $base-bg, 5% ) !default;
$dropdown-color: null !default;

$item-font-size: 16px !default;
$item-line-height: 30px !default;
$item-padding: 8px 15px !default;
$icon-height: 20px !default;
$icon-width: 20px !default;