:root[color-mode="dark"] {
  --brand-hue: 231;
  --brand-saturation: 57%;
  --brand-lightness: 15%;

  --text1: #ffffff;
  --text2: hsl(var(--brand-hue), 5%, 65%);

  // highlight
  --text3-hue: 164;
  --text3-saturation: 89%;
  --text3-lightness: 69%;
  --text3: hsl(var(--text3-hue), var(--text3-saturation), var(--text3-lightness));
  --text3-transparent: hsl(var(--text3-hue), var(--text3-saturation), var(--text3-lightness), 0.25);

  --text4-hue: 211;
  --text4-saturation: 65%;
  --text4-lightness: 65%;
  --text4: hsl(var(--text4-hue), var(--text4-saturation), var(--text4-lightness));
  --text4-1: hsl(var(--text4-hue), calc(var(--text4-saturation) + 20%), calc(var(--text4-lightness) + 20%));
  --text4-2: hsl(var(--text4-hue), calc(var(--text4-saturation) - 20%), calc(var(--text4-lightness) - 20%));

  // "on hover" links
  --text5: #10ff00;

  --text6: #4591ff;
  --text7: #98a6d5;

  --text-disabled: #333;
  --text-em: #c5e1f5;
  --text-notice: var(--text6);
  --text-error: #ff0000;

  --surface1: #10173c;
  --surface1-transparent: #10173c00;

  // on-hover
  --surface2: hsl(var(--brand-hue), calc(var(--brand-saturation) + 15%), var(--brand-lightness));
  --surface2-transparent: hsl(var(--brand-hue), calc(var(--brand-saturation) + 15%), var(--brand-lightness), 0);

  --surface3: hsl(var(--brand-hue), calc(var(--brand-saturation) + 25%), calc(var(--brand-lightness) + 10%));
  --surface4: hsl(var(--brand-hue), calc(var(--brand-saturation) + 35%), calc(var(--brand-lightness) + 15%));
  --surface5: #3e3e6b;
  --surface5-transparent: #3e3e6b00;

  // Slightly lighter than the background
  --surface6: #20253e;

  --surface7: #7367f0;

  // Node colors
  // Increase the HSL lightness by 5% for the tinted properties
  --node-color-1: hsl(231 56% 14%);
  --node-color-1-tinted: hsl(231 56% 19%);
  --node-color-2: hsl(290 55% 38%);
  --node-color-2-tinted: hsl(290 55% 43%);
  --node-color-3: hsl(225, 29%, 41%);
  --node-color-3-tinted: hsl(225, 29%, 46%);
  --node-color-4: hsl(206 66% 32%);
  --node-color-4-tinted: hsl(206 66% 37%);

  // Chatbot
  --chatbot-container-bg: #30648a;

  // Chart colors
  --chart-title-color: var(--text2);
  --chart-bg: var(--surface5);
  --chart-fill-color: var(--text1);
  --chart-tick-color: var(--text4);
  --chart-gradient-start: #0000ff; // Avoid leading space before RGB value
  --chart-gradient-end: #00065e; // Avoid leading space before RGB value

  // Background sphere
  --sphere-color-1: #fff;
  --sphere-color-2: #f0f;
  --sphere-color-3: #0ff;

  --blob-file-upload-gradient-start: var(--surface6);
  --blob-file-upload-gradient-end: var(--surface1);
  --btn-close-filter: invert(1) grayscale(100%) brightness(200%);
  --btn-primary-hover-color: var(--text1);
  --drill-progress-circle-full: var(--surface5);
  --drill-progress-circle-partial: var(--text5);
  --drag-color: var(--text3);
  --dropdown-item-extra-color: var(--text2);
  --fitness-overdue-bg: var(--surface7);
  --fitness-overdue-color: var(--text1);
  --form-bg: var(--surface5);
  --form-bg-disabled: var(--surface2);
  --form-bg-focus: var(--surface6);
  --form-border-color-hover: var(--text3);
  --form-color: var(--text4);
  --hover-bg: var(--surface3);
  --link-color: var(--text3);
  --notice-color: var(--text1);
  --notice-title-color: var(--text1);
  --search-splitter-bg: var(--surface6);
  --search-splitter-color: var(--text6);
  --selected-bg: var(--surface5);
  --selected-color: var(--text1);
  --selected-secondary-color: var(--text4);
  --sidebar-active-bg: var(--surface5);
  --sidebar-active-color: var(--text3);
  --sidebar-bg: var(--surface6);
  --sidebar-color: var(--text1);
  --sidebar-gradient-start: var(--surface5);
  --sidebar-gradient-end: var(--surface1);
  --sidebar-hover-bg: var(--surface3);
  --sidebar-hover-color: var(--text3);
  --sidebar-icon-color1: var(--text4);
  --sidebar-icon-color2: var(--text3);
  --sidebar-icon-color3: var(--text6);
  --title-gradient-end: hsl(204 100% 59%);
  --title-gradient-start: hsl(98 100% 62%);
  --toggle-button-color-toggled: var(--text4);
  --tooltip-bg: var(--surface3);
  --tooltip-color: var(--color1);

  // Override Bootstrap CSS properties
  --bs-body-color: var(--text1);
  --bs-body-bg: var(--surface1);
  --bs-list-group-color: var(--text3);
  --bs-list-group-item-padding-x: 1.25rem;
  --bs-list-group-item-padding-y: .75rem;
  --bs-list-group-item-secondary: var(--text3);
  --bs-modal-header-border-color: var(--text3);
  --bs-secondary: var(--text3);
  --card-spacer-y: 1.25;
  --card-spacer-x: 1.25;

  // Oruga
  --oruga-table-color: var(--text1);
  --oruga-table-background-color: transparent;
  --oruga-table-hoverable-background-color: var(--hover-bg);
  --oruga-table-td-border: var(--text1);

  // Vue MultiSelect
  --vms-controls-color: var(--text2);
  --vms-controls-color-hover: var(--text1);
  --vms-selected-bg: var(--surface2);
  --vms-selected-color: var(--text4);

  // Vue 3 Datepicker
  --vdp-bg-color: var(--surface1);
  --vdp-heading-hover-color: var(--surface4);
  --vdp-hover-bg-color: var(--surface3);
  --vdp-text-color: var(--text1);
  --vdp-selected-bg-color: var(--surface5);

  .list-group {
    --bs-list-group-border-color: var(--surface5);
  }

  // Media Chrome
  --media-control-background: var(--surface1);
  --media-background-color: var(--surface2);
  --media-icon-color: var(--text1);
}
