$base-bg: #fff;
$item-color: #262626;
$icon-bg: #bbc5d6;
$icon-active-color: #fff;
$icon-active-bg: $item-color;
$item-hover-bg: rgba(darken($base-bg, 5%), 0.5);
$dropdown-bg: #e3e3e3;

.v-sidebar-menu.vsm_white-theme {
  background-color: $base-bg;
  .vsm--link {
    color: $item-color;
    &_exact-active,
    &_active {
      color: $item-active-color;
      background-color: $item-active-bg;
    }
    &_level-1 {
      &.vsm--link_exact-active,
      &.vsm--link_active {
        box-shadow: 3px 0px 0px 0px $primary-color inset;
        & .vsm--icon {
          color: $icon-active-color;
          background-color: $icon-active-bg;
        }
      }
      & .vsm--icon {
        background-color: $icon-bg;
      }
    }
    &_hover,
    &:hover {
      color: $item-hover-color;
      background-color: $item-hover-bg;
    }
    &_mobile-item {
      color: $mobile-item-color;
      &.vsm--link_hover,
      &:hover {
        color: $mobile-item-color;
      }
    }
  }

  &.vsm_collapsed {
    .vsm--link_level-1.vsm--link_hover,
    .vsm--link_level-1:hover {
      .vsm--icon {
        color: $mobile-icon-color;
        background-color: $mobile-icon-bg;
      }
    }
  }

  .vsm--icon {
    color: $icon-color;
  }

  .vsm--dropdown {
    & .vsm--list {
      background-color: $dropdown-bg;
    }
    & .vsm--link {
      color: $dropdown-color;
    }
    & .vsm--icon {
      color: $dropdown-color;
    }
  }

  .vsm--mobile-bg {
    background-color: $mobile-item-bg;
  }

  &.vsm_expanded {
    .vsm--item_open {
      .vsm--link {
        &_level-1 {
          color: $item-open-color;
          background-color: $item-open-bg;
          & .vsm--icon {
            color: $icon-open-color;
            background-color: $icon-open-bg;
          }
        }
      }
    }
  }

  &.vsm_rtl {
    .vsm--link_level-1.vsm--link_active,
    .vsm--link_level-1.vsm--link_exact-active {
      box-shadow: -3px 0px 0px 0px $primary-color inset;
    }
  }

  .vsm--header {
    color: rgba($item-color, 0.7);
  }
  .vsm--badge_default {
    color: $item-color;
    background-color: darken( $base-bg, 5% );
  }
  .vsm--toggle-btn {
    color: $item-color;
    background-color: darken( $base-bg, 5% );
  }
}