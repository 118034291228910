@import "theme-dark.scss";
@import "theme-light.scss";
@import "theme-purple.scss";

// Custom Bootstrap Sass variables
// We use Sass because as of Bootstrap 5.2 there are no CSS property equivalents
$table-cell-padding-y: .75rem;
$table-cell-padding-x: .75rem;

// Import the Bootstrap core
// (This file imports all Bootstrap core's variables and components from the Bootstrap core package installed via npm)
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// Import CSS for perfect-scrollbar
@import "../css/perfect-scrollbar.css";

// Import CSS for Prism syntax highlighter
@import "../../node_modules/prismjs/themes/prism-okaidia.css";

// Import CSS for vue-sidebar-menu
@import "../css/vue-sidebar-menu/vue-sidebar-menu.scss";

// Import CSS for Floating Vue tooltip
@import "../../node_modules/floating-vue/dist/style.css";

// Import core SCSS
@import "./base";
